<template>
  <div style="padding-bottom: 32px;">
    <el-tabs v-model="activeName">
      <el-tab-pane label="浙江嘉兴（省）" name="first">
        <jiaxing-province-list></jiaxing-province-list>
      </el-tab-pane>
      <el-tab-pane label="浙江嘉兴（市）" name="second">
        <jiaxing-city-list></jiaxing-city-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import JiaxingCityList from "@/views/passport/jiaxing/JiaxingCityList";
import JiaxingProvinceList from "@/views/passport/jiaxing/JiaxingProvinceList";

export default {
  components: { JiaxingProvinceList, JiaxingCityList },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {},
};
</script>

<style scoped></style>
